import BidButton from "./BidButton";
import BuyButton from "./BuyButton";
import CloseButton from "./CloseButton";
import ClosingButton from "./ClosingButton";
import ClaimingButton from "./ClaimingButton";
import LoadingButton from "./LoadingButton";
import { useEffect, useState } from "react";
import { bigNumberToNumberFromSome, formatCurrency, formatCurrencyFromSome, formatCurrencyFromSome2, getAccInfo, getAmtForContract } from "../../functions";
import BuyingButton from "./BuyingButton";
import moment from "moment"
const APIButtons = ({
  acc,
  address,
  views,
  apis,
  showing,
  stdlib,
  closed,
  bidDefaultValue,
  startPrice,
  reservePrice,
  decimals,
  unitname
}) => {
  const [bid, setBid] = useState(bidDefaultValue)
  const [state, setState] = useState({
    loading: false,
    bid: bidDefaultValue,
    showing
  })
  // EFFECT: UPDATE MIN BID
  useEffect(() => {
    const interval = setInterval(() => {
      Promise.all([
        views.minBid(),
        views.startPrice(),
      ])
        .then(([
          minBid,
          startPrice,
        ]) => {
          const fMinBid = formatCurrencyFromSome2(stdlib, minBid, decimals)
          const fStartPrice = formatCurrencyFromSome2(stdlib, startPrice, decimals)
          if (parseFloat(fMinBid) > parseFloat(`${bid}`) || parseFloat(fStartPrice >= parseFloat(`${bid}`))) {
            setBid(Math.max(parseFloat(fMinBid), parseFloat(fStartPrice)))
          }
        })
    }, 4000)
    return () => clearInterval(interval)
  }, [bid])
  const ACTION = {
    touch: (payload) => {
      apis.Bid.touch(null)
        .then(payload)
        .catch(e => setState({ ...state, loading: false }))
    },
    optin: (payload, tokenV = 'token') => {
      Promise.all([
        getAccInfo(address),
        views[tokenV](),
      ])
        .then(([{ data }, token]) => {
          let { assets } = data
          let formattedToken = bigNumberToNumberFromSome(stdlib, token)
          if (!assets.some(el => el['asset-id'] === formattedToken)) {
            acc.tokenAccept(formattedToken)
              .then(() => {
                payload()
              })
              .catch((e) => setState({ ...state, loading: false })) // user doesn't sign
          } else {
            payload()
          }
        })
        .catch((e) => setState({ ...state, loading: false })) // user doesn't sign
    },
    optinBoth: (payload) => {
      ACTION.optin(() => 
        ACTION.optin(() => 
          payload(), 'tokenP'), 'token')
    },
    optinTouch: (payload) => {
      ACTION.optinBoth(() =>
        ACTION.touch(payload))
    },
    claim: () => {
      setState({ ...state, loading: true })
      ACTION.optinTouch(() => {
        apis.Bid.close(null)
          .then(() => {
            /*
            onClose()
            enqueueSnackbar('NFT claimed!', {
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
            })
            */
            setState({ ...state, loading: false })
          })
          .catch((e) => setState({ ...state, loading: false })) // user doesn't sign
      })
    },
    close: () => {
      setState({ ...state, loading: true })
      ACTION.touch(() => {
        apis.Bid.close(null)
          .then(() => {
            /*
            onClose()
            enqueueSnackbar('Auction closed!', {
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
            })
            */
            setState({ ...state, loading: false, over: true })
          })
          .catch((e) => setState({ ...state, loading: false })) // user doesn't sign
      })
    },
    bid: async () => {
      let bal = await stdlib.balanceOf(acc)
      let assetCount = ((await getAccInfo(acc?.networkAccount?.addr ?? ""))?.data?.assets ?? []).length
      let minBid = await views.minBid()
      let startPrice = await views.startPrice()
      let endSecs = await views.endSecs()
      let fBal = Math.round(10 * (parseFloat(stdlib.formatCurrency(bal)) - /*minimum balance*/assetCount * 0.1 - 0.1)) / 10
      let fMinBid = Math.round(formatCurrencyFromSome(stdlib, minBid) * Math.pow(10, 6 - decimals) + 1) 

      let fStartPrice = Math.round(formatCurrencyFromSome(stdlib, startPrice) * Math.pow(10, 6 - decimals))
      let fEndSecs = parseInt(bigNumberToNumberFromSome(stdlib, endSecs))
      let now = moment().unix()
      let fBid = Math.round(parseFloat(bid) * 10000) / 10000
      let closed = fEndSecs <= now
      let guard = Math.abs(fEndSecs - now) < 60
      let closure = closed || guard ? ACTION.optinTouch : ACTION.optinBoth
      /*
      if (fBid < fMinBid || fBid < fStartPrice) {
        alert(`Bid too low: must be greater or equal to ${Math.max(fMinBid, fStartPrice)}`)
        return
      }
      else if (fBal <= fBid) {
        alert(`Insuffient balance: must be greater than or equal to bid amount`)
        return
      }
      */
      setState({ ...state, loading: true })
      closure(() => {
        apis.Bid.getBid(getAmtForContract(stdlib)(bid, decimals))
          .then((msg) => {
            Promise.all([
              views.currentPrice(),
            ])
              .then(([cp]) => [formatCurrencyFromSome2(stdlib, cp, decimals)])
              .then(([cp]) => {
                setBid(cp)
                setState({ ...state, show: "bid"})
              })
          })
          // user doesn't sign
          // 
          .catch((e) => {

            console.log(e)
            setState({ ...state, loading: false })
          }) // user doesn't sign
      }) // TODO: move payload inside closure
    },
    buy: async () => {
      setState({ ...state, loading: true })
      ACTION.optinTouch(() => {
        apis.Bid.getPurchase(stdlib.parseCurrency(reservePrice, decimals))
          .then((msg) => {})
          .catch((e) => {
            console.log(e)
            console.log("Purchase not allowed")
            setState({ ...state, loading: false }) // user doesn't sign purchase
          }) 
      })
    }
  }
  const handleChange = ({ target }) => setBid(target.value)
  let button = () => {
    switch (showing) {
      case 'loading':
        return <LoadingButton />
      case 'bid':
        return state.loading
          ? <LoadingButton />
          : <>
            <BidButton iconName="btc" unitname={unitname} onClick={ACTION["bid"]} onChange={handleChange} value={bid} />
          </>
      case 'close':
        return state.loading
          ? <ClosingButton />
          : state.over
            ? ""
            : <>
            <CloseButton onClick={ACTION["close"]} />
            </>
      case 'claim':
        return state.loading
          ? <ClaimingButton />
          : state.over
            ? ""
            : <>
            <CloseButton variant="claim" onClick={ACTION["claim"]} />
            </>
      case 'claiming':
        return <LoadingButton variant="claim" />
      case 'closing':
        return <ClosingButton />
      case 'buy':
        return state.loading
          ? <BuyingButton />
          : <BuyButton reservePrice={reservePrice} onClick={ACTION["buy"]} />
      case 'closed':
      default:
        return ""
    }
  }
  return <>
    {/*state.loading ? <LoadingButton /> : button()*/}
    {!closed ? button() : ""}
  </>
}
export default APIButtons;