import { FeatureFlagContext } from '../context/FeatureFlagContext';
import { useState, useEffect } from 'react'
import * as backendBank from '../build/bank.main.mjs'
import {
  useHistory,
  useLocation
} from "react-router-dom";
import wallet from '../wallet.svg';
import logo from '../logo.svg';
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Icon from "react-crypto-icons";
import { formatCompactAddress } from '../functions';
import useCopy from '../hooks/useCopy';
import styles from './navbar.module.css'
import SettingsIcon from '@mui/icons-material/Settings';
import MenuIcon from '@mui/icons-material/Menu';
import CheckIcon from '@mui/icons-material/Check';
import { Button, Form, FormControl, NavDropdown, Offcanvas } from 'react-bootstrap';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';

const axios = require('axios')

const { REACT_APP_NETWORK_PROVIDER, REACT_APP_NETWORK } = process.env
const providerEnv = REACT_APP_NETWORK_PROVIDER || "TestNet"
let algoexplorerapi_endpoint
if (providerEnv === "MainNet") {
  algoexplorerapi_endpoint = 'https://algoexplorerapi.io'
} else {
  algoexplorerapi_endpoint = 'https://testnet.algoexplorerapi.io'
}
const isTestNet = providerEnv === "TestNet"
const isMainNet = providerEnv === "MainNet"
const isMnemonic = localStorage.getItem('walletFallback') === 'Mnemonic'
const isMyAlgoConnect = localStorage.getItem('settingsWalletFallback') === "MyAlgoConnect"
const isWalletConnect = localStorage.getItem('settingsWalletFallback') === "WalletConnect"


const AccountInfo = ({
  style,
  acc,
  stdlib,
  handleDisconnect,
}) => {
  let { address } = acc
  const [bal, setBal] = useState(Number(acc.amount / 1000000).toFixed(2))
  const copy = useCopy()
  useEffect(() => {
    let interval = setInterval(() => {
      console.log(new Date())
      stdlib.balanceOf(acc)
        .then(newBal => stdlib.formatCurrency(newBal, 2))
        .then(newBal => {
          if (bal !== newBal) {
            setBal(newBal)
          }
        })
    }, 5000)
    return () => clearInterval(interval)
  }, [])
  const rstyle =
  {
    "margin": "auto"
  }
  const iconStyle = {
    "fontSize": "17px",
    "marginLeft": "8px",
    "verticalAlign": "sub"
  }
  return <>
    <span className="d-none d-md-inline-block" style={{ ...style, ...rstyle, "fill": "white" }}>
      <Icon name="algo" size={11} />&nbsp;{bal}
    </span>
    {((addr) => <span style={{ ...style, ...rstyle }}>
      <span onClick={() => copy(addr)}>{formatCompactAddress(addr)}</span>
      <ExitToAppIcon role="button" onClick={handleDisconnect} className="app-info-exit-icon" style={{ ...style, ...rstyle, ...iconStyle }} />
    </span>)(address)}
  </>
}

const NFTJamNavbar = (props) => {
  let {
    acc,
    handleConnect,
    stdlib,
    APP_ID_BANK,
    ASSET_ID_JAM
  } = props
  const copy = useCopy()
  let location = useLocation()
  let history = useHistory()
  const [optin, setOptin] = useState(false)
  const [loadingJam, setLoadingJam] = useState(false)
  const [state, setState] = useState({
    bank: null,
    buy: null,
    viewAmount: 0
  })
  /*
  useEffect(() => {
    if (state.bank && state.viewAmount > 0) {
      (async () => {
        const interval = setInterval(() => {
          (async () => {
            const viewAmount = bigNumberToNumberFromSome(await (await state.bank.getViews()).Bank.amount())
            if(state.viewAmount !== viewAmount) {
              state.buy(false)
            }
          })()
        }, 5000)
        //$clearInterval(interval)
      })()
    }
  })
  */
  const getAppInfo = async (appId) =>
    await axios.get(`${algoexplorerapi_endpoint}/v2/applications/${appId}`)
  const somethingFromSome = (f, d) => (some) => some[1] ? f(some[1]) : d
  const bigNumberToNumberFromSome = (some) => somethingFromSome((some) => stdlib.bigNumberToNumber(some), 0)(some)
  // EVENTS
  const handleBank = async () => {

    // ALGO ONLY BEGIN
    if (acc.assets.filter(el => el['asset-id'] === ASSET_ID_JAM).length === 0) {
      console.log("Opting in to token JAM Coin")
      await acc.tokenAccept(ASSET_ID_JAM)
    }
    setOptin(true)
    // ALGO ONLY END

    setLoadingJam(true)
    let total = 1000
    if (!state.bank)
      try {
        await getAppInfo(APP_ID_BANK)
        const ctc = acc.attach(backendBank, APP_ID_BANK)
        await Promise.all([
          backendBank.Consumer(ctc, {
            getBid: () => new Promise(async (resolve) => {
              const viewAmount = bigNumberToNumberFromSome(await (await ctc.getViews()).Bank.amount())
              console.log({ viewAmount })
              console.log({ total })
              total -= 2
              if (total >= viewAmount) {
                resolve(false)
              } else {
                setLoadingJam(false)
                setState({
                  ...state,
                  viewAmount,
                  bank: ctc,
                  buy: resolve
                })
              }
            }),
            signal: () => {
              console.log("Signal!")
            },
            ...stdlib.hasConsoleLogger
          })])
      } catch (e) { }
  }
  const handleBuy = async () => !state.bank
    ? handleBank()
    : state.buy
    && state.buy(state.viewAmount === bigNumberToNumberFromSome(await (await state.bank.getViews()).Bank.amount()))

  // STYLE
  const navbarBrandNameStyle = {
    lineHeight: '62px',
    marginLeft: '8px'
  }
  const navbarBrandTypographyStyle =
  {
    "fontFamily": "Rubik Mono One",
    "fontStyle": "normal",
    "fontWeight": "normal",
    "fontSize": "22px",
    "lineHeight": "27px"
  }
  const navbarLinkTypographyStyle =
  {
    "fontFamily": "Rubik",
    "fontSize": "14px",
    "fontStyle": "normal",
    "fontWeight": "700",
    "lineHeight": "17px",
    "letterSpacing": "0em",
    "textAlign": "center"
  }
  const navbarLinkStyle =
  {
    "color": "white",
    "textAlign": "center",
    "textTransform": "uppercase"
  }
  const navbarLinkWalletConnectStyle =
  {
    "display": "flex",
    "alignItems": "center"
  }
  const navbarLinkWalletConnectIconStyle =
  {
    "top": "-5px",
    "filter": "invert(1)",
    "height": "17px"
  }
  const navbarLinkWalletConnectLabelStyle =
  {
    "marginLeft": "7px"
  }
  const navbarStyle =
  {
    "padding": "26px",
    "color": "white"
  }
  const navbarCollapseStyle = {
    "justifyContent": "flex-end",
  }
  return <FeatureFlagContext.Consumer>
    {({
      sellPage,
      buyPage,
      browsePage,
      navbar
    }) =>
      <Navbar style={navbarStyle}>
        <Container>
          <Navbar.Brand className="text-light" onClick={() => history.push("/")}>
            <img
              src={logo}
              width="33"
              height="42"
              className="d-inline-block"
              alt="NFT Jam Logo"
            />
            <div className="d-inline-block" style={{ ...navbarBrandNameStyle, ...navbarBrandTypographyStyle }}>
              NFT Jam
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" style={navbarCollapseStyle}>
            <Nav style={{ columnGap: '1em', fontWeight: '500', "alignItems": "center" }}>
              {[
                /*
                {
                  text: !loadingJam ? "Buy JAM" : <>
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      style={{ "height": "12px", "width": "12px" }} />
                    Loading...
                  </>,
                  onClick: handleBuy,
                },
                */
                {
                  path: "/collection",
                  text: "Collections",
                  className: (location.pathname.indexOf('collection') !== -1 ? "active" : "") + " d-none d-md-block",
                  visible: true
                },
                {
                  path: "/buy",
                  text: "Buy NFT",
                  className: (location.pathname.indexOf('buy') !== -1 ? "active" : "") + " d-none d-md-block",
                  visible: navbar.buy && !isMnemonic
                },
                {
                  path: "/sell",
                  text: "Sell NFT",
                  className: (location.pathname.indexOf('sell') !== -1 ? "active" : "") + " d-none d-md-block",
                  visible: navbar.sell && !isMnemonic
                },
                {
                  path: `/auction`,
                  text: "Auction",
                  className: (location.pathname.indexOf('auction') !== -1 ? "active" : "") + " d-none d-md-block",
                  visible: false
                },
                {
                  path: '/browse',
                  text: "Browse",
                  className: (location.pathname.indexOf('browse') !== -1 ? "active" : "") + " d-none d-md-block",
                  visible: browsePage
                },
                {
                  path: "/faq",
                  text: "FAQ",
                  className: (location.pathname.indexOf('faq') !== -1 ? "active" : "") + " d-none d-md-block",
                  visible: true
                },
                {
                  path: '#',
                  className: "d-xs-block d-md-none",
                  text: <NavDropdown menuVariant="dark" title={<MenuIcon style={{ color: "white" }} />} id="nav-dropdown">
                    {sellPage && !isMnemonic && <NavDropdown.Item eventKey="4.1" onClick={() => history.push('/sell')}>SELL NFT</NavDropdown.Item>}
                    <NavDropdown.Item eventKey="4.2" onClick={() => history.push('/auction')}>AUCTION</NavDropdown.Item>
                    <NavDropdown.Item eventKey="4.3" onClick={() => history.push('/browse')}>BROWSE</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item eventKey="4.4">FAQ</NavDropdown.Item>
                  </NavDropdown>,
                  visible: true,
                },
                {
                  path: '#',
                  text: <NavDropdown menuVariant="dark" title={<SettingsIcon style={{ color: "white" }} />} id="nav-dropdown">
                    <NavDropdown.Item eventKey="4.5" onClick={() => window.location = 'https://testnet.nftjam.net'}>
                      {isTestNet ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />} TestNet
                    </NavDropdown.Item>
                    <NavDropdown.Item eventKey="4.6" onClick={() => window.location = 'https://app.nftjam.net'}>
                      {isMainNet ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />} MainNet
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    {false && <NavDropdown.Item eventKey="4.7" onClick={() => {
                      localStorage.setItem('walletFallback', 'Mnemonic')
                      localStorage.setItem('settingsWalletFallback', 'Mnemonic')
                      history.go("")
                    }}>Mnemonic</NavDropdown.Item>}
                    <NavDropdown.Item eventKey="4.8" onClick={() => {
                      localStorage.setItem('walletFallback', 'MyAlgoConnect')
                      localStorage.setItem('settingsWalletFallback', 'MyAlgoConnect')
                      history.go("")
                    }}>
                      {isMyAlgoConnect ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />} MyAlgo Connect
                    </NavDropdown.Item>
                    <NavDropdown.Item eventKey="4.9" onClick={() => {
                      localStorage.setItem('walletFallback', 'WalletConnect')
                      localStorage.setItem('settingsWalletFallback', 'WalletConnect')
                      history.go("")
                    }}>
                      {isWalletConnect ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />} Wallet Connect
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    {localStorage.getItem('walletFallback') === 'Mnemonic'
                      ? <NavDropdown.Item eventKey="4.9" onClick={handleConnect}>
                        Connect
                      </NavDropdown.Item>
                      : <NavDropdown.Item eventKey="4.9" onClick={() => {
                        localStorage.setItem('walletFallback', 'Mnemonic')
                        history.go("")
                      }}>Disconnect</NavDropdown.Item>}
                  </NavDropdown>,
                  visible: true
                },
                {
                  path: '#',
                  className: "d-block",
                  text: <NavDropdown menuVariant="dark" title={<AccountBalanceWalletIcon style={{ color: "white" }} />} id="nav-dropdown">
                    {acc && <>
                    <NavDropdown.Item onClick={()=>copy(acc?.address)}>{formatCompactAddress(acc?.address)}</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item style={{fill: "white"}}><Icon name="algo" size={11} />&nbsp;{stdlib.formatCurrency(acc.amount)}</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={() => {
                      copy(acc?.address)
                      window.open(
                        "https://testnet.algoexplorer.io/dispenser",
                        "Algorand Testnet Dispenser",
                        "left=100,top=100,width=640,height=640")
                    }} style={{fill: "white", display: 'flex', alignItems: 'center'}} href="https://testnet.algoexplorer.io/dispenser" target="_blank" rel="noopener noreferrer"><LocalGasStationIcon fontSize='small' />&nbsp;Dispenser</NavDropdown.Item>
                    </>}
                  </NavDropdown>,
                  visible: acc && acc.address !== props.ADDR_MNEMONIC
                },
              ]
                .filter(({ visible }) => visible)
                .map(el =>
                  <Nav.Link style={{ ...navbarLinkStyle, ...navbarLinkTypographyStyle }} className={[el.className].join(" ")} onClick={el.onClick ? el.onClick : () => history.push(el.path)}>{el.text}</Nav.Link>
                )}
              {acc && acc?.address !== props.ADDR_MNEMONIC ? (
                false && <AccountInfo {...props} style={navbarLinkTypographyStyle} />
              ) : (
                false && <span role="button" onClick={handleConnect} style={{ ...navbarLinkStyle, ...navbarLinkTypographyStyle, ...navbarLinkWalletConnectStyle }}><img src={wallet} style={{ ...navbarLinkWalletConnectIconStyle }} alt="Algorand Wallet Icon" width="30" height="26" /><span style={{ ...navbarLinkWalletConnectLabelStyle }}>Connect <span className={"d-none d-md-inline-block"}>wallet</span></span></span>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>}
  </FeatureFlagContext.Consumer>
}

export default NFTJamNavbar;
