const { REACT_APP_NETWORK_PROVIDER } = process.env
let providerEnv = REACT_APP_NETWORK_PROVIDER
let collection = {
    MainNet: {
        "algomonke": ["MONKE4DS7FSV7KKAXAEHKVDFD6746SK6A26X3RR4KT4PZ3TTUMWOD3AX2M", "MONKEDDNKBAIUN54F5TZAXARZQV4CG7BL2CMS67BLY45ND4V5SBHGYX5H4", "MONKEUTKI4SCUCZFW4KH3SKWQCNPQEKYJPRPSXTMPZGKG3RZZCXU5IBI34", "MONKEC77TXVM762QENWJ23UOMCOBZ2RLMTY6CWNOOHGT3BED5VYD46BWBA", "MONKEAUKWQ7T6DZ2JJQ243K5QC2OKF7WMJGZP3HQMMTFGF2EYWID5ZPPVU"],
        "the-parliament-of-aowls": ["AOWL3ZOC55JJDB5LU5CCIVK3WITVXMVZU6PV2CQQOLXUNCTOJQ6VJCPYAU", "AOWLDAJSAIHNR4J2TKB4U32Z73O7W52JGJUGUR5RW6ZH46G3ET4IFNNGQ4", "AOWLLUX3BBLDV6KUZYQ7FBZTIWGWRRJO6B5XL2DFQ6WLITHUK26OO7IGMI"],
        "mngo": ["MNGOLDXO723TDRM6527G7OZ2N7JLNGCIH6U2R4MOCPPLONE3ZATOBN7OQM", "MNGORTG4A3SLQXVRICQXOSGQ7CPXUPMHZT3FJZBIZHRYAQCYMEW6VORBIA", "MNGOZ3JAS3C4QTGDQ5NVABUEZIIF4GAZY52L3EZE7BQIBFTZCNLQPXHRHE", "MNGO4JTLBN64PJLWTQZYHDMF2UBHGJGW5L7TXDVTJV7JGVD5AE4Y3HTEZM"],
        "tinyhorse": ["TINYGXPTQUIKUFF6HOUK5SDTTK4EZ3HJOBUCCWNDZ6QJYSZMXQTUSQPQZY", "TINYGNROP4U5RO444XQHM4HKVMYDFAVHIRLYPCXM7DBOF6CS2OZM7OMOMI"],
        "al-goanna": ["D5J7H7PIYKLY2U6A5OFUAC7GQHTHSXXNX65DSD3CJYPBV2MVK6NTNW44CA"],
        "algoplanets": ["PLNTKFIP4UOGJARQMEXQYHBQPJURARFRLTR6AEGWG6SPFZLFOZHYYXCAV4"],
        "pixel-guys": ["UN7I77ZLE4STXMHP4BCDWSBTS4XPB5YDU33CYQ6UNWLWEB6ZXZZ5ZF3BWU"],
        "the-algomancers": ["ZSO6BGZKNXNY3BKOEVJJPD3JC3WTEUPYNLQCLS6B3SASY3TB3MXJJUE7YA"],
        "mafialgo": ["4XKREKGYJ2PYXYY2A3CRLK673ANJB3M26ZBJF53KZ37FAE62GPPXY6JVG4"],
        "moon-dudes": ["MOON3QNZT5XBPIYLMASRS5RSFRVVZF4SAMG4Q22KQFRLWKXXW2S2MY2WD4"],
        "tiny-trolls": ["TROLL5LPQXSQXP75KWEYTL2RQPZY3FU5XNQ4VA7W3UP7XVJIMTDYTXOWCU"],
        "pixel-dragons": ["DRGNZZNX4ZSN6CUM7MZRL6XXVATWOVVSGTJRCLV6RQ2OGRSTOIWRZI5H5A", "DRGN4IO3D7W7ICTE32AMFBZ3HHDTDXPPXOMWX4Z7D5ZCCIUIVTBTCLFOPQ"],
        "rocketeer": ["YKGPPKMCERYT6Z3INDSSICUHSZDEMNOA7KELGPJMBMQNYOLSGGEVF3R7I4"],
        "algobones": ["56G2QEJXW7EOQL3KF4AXE4EZC6PTHW2CQ2S6TRWOFYNHOTEGO2HQAIKB5A"],
        "algato": ["GATODL62IJT47IP4F2VMCMZLWRWGV7EUPWX6FBRPFFB4R5LQAOYLVWBLV4"],
        "raptoreye": ["EYERHFMTLLBVVDV4RKGKSTMWU4QZ7IG5HUEXQYO3UVX4ABQ5MDNJIKN7HQ"],
        "algorhythms": ["BXCHMPPPUG32272VKOUHVCDKYPSUPCUBW7OCINRNW2IKPUDFECI6N4QTPU"],
        "dope-peanut-society": ["PNUTDUODKUYPERVZQM75QHPCQXOZQCPTLKYN3XSK63FN3TFYJUUSJXWSFQ"],
        "bug-eyed-beasts": ["TYLMCYU7RLOEEBV5V2SKRZB2NFLSPPI2TCYBANYDUUPTIZDFBCOFJI6XKA"],
        "algogods": ["JRB3NLSEZYDITLUYXKCEO7T4PFUWPP7QLNCNAK2L5J6QOP5PBHM4N3CKZ4"],
        "rugs": ["RUGSVETYW67CNQB5ZBJXYRZR5ZS5TXCQWFRACISKGEJ5N6YXYKTGJUTGRM", "RUG2PYQAYXNDR4UWUEBUX5NMDRS2VS73IY2F6LDEFHWXAHZRCU7FHTE3IE"],
        "stupidhorse": ["GLOW7AKCAZXWQRPI6Q7OCVAO75H45AIYMTDEH3VNPETKYFXMNHAMQOVMS4", "STPD5WZ7DMF2RBBGROROWS6U2HNKC4SOHZXTFDTRIWHTXQ46TA7HU3A2SI"],
        "flemish-giants": ["PIIQQI6WFNT3S6VEMNTQZNWLDUYXUEWRLZLTBWMJABEGPUZXML2Y5SKC6A"],
        "bored-alien-squad": ["BOREDO6HFSCNSEJHW53MDTBBWHBBCPOZPDVXQ5EIC4G66UIBEABHH75NXY"],
        "algo-apes": ["BGJB5YRSTMZCZGTPGEKODMPBJMU4TR42EZYL3J463E6KOALQIDCGWYBPSQ"],
        "skullkrakens": ["HRSF4J5RGKDMNW5AHCAVWMVE5D2SCEB2TEDA3RBY5MJWOAXEFE76K5CW3E", "DMG5ZX62GUPVPNMNVWZGM653IGOPDFVCRGKFBNQKCK5RQ6OCW3CFQEZNKU"],
        "space-monkeys": ["S3ZR6JCM2MISNR3YN3TRINQLVD7EIB6KPE27HTCS26JHSQTFMHX6TV4BYQ"],
        "incognito-alien-club": ["IACII2KW5LSVKZKSJ5MMC77745CSVROOV47CEZQ7K75Q7TFKWXK5HNCK5Q"],
        "mutinous": ["GYXGPVYZLOJ5ON5JPGYEVJUD22RZXLFGAMJA5BRBVDPQJUNN3WHVF6GLNQ"],
        "crypto-squirrel-association": ["ACORNOX3WJKR2GX63IJGPC2MV6E7GRNBBEE22BXQ6H7HFMEXLUL3RSEBLY"],
        "algorillas": ["PO4CEJB6IV2P5UACZ3P77KJCITMX2ZIT6RMW4WTX6JQGJYNJS6T5E4V27Q"],
        "monsti": ["KEFVKQAEKQBFHS5UJMC3JL7OSODFIEBYRAYXDXL5UL4WEBHSVMB2EBITP4"],
        "yieldlings": ["5DYIZMX7N4SAB44HLVRUGLYBPSN4UMPDZVTX7V73AIRMJQA3LKTENTLFZ4"],
        "mingo-nfts": ["MINGORFYSRDIIC6HO7MAGA2LZQ3NQ7RLYNAQPRUME55UMDVZGVS6BPAK2E"],
        "algo-lynx": ["3EHGD765J2LEFVLOGHPBQ7GCMF2ALFLMF77ANU5VVG3HVFLR55NLGT4F44"],
        "trinleys-experiments": ["KLKLHRAT525BOEPR72XMBIPDK77AI52TF6QC5LGNV2J2J3SCUGKDJVLCX4"],
        "algo-komodo": ["FZH3CPKWMSBC4XBIPREPYQ7SDCELPTJZZSPP4VE7KL3IMFPD4X4JP5EP4U"],
    },
    TestNet: {
        "test-collection": ["LOXKESTUNTAAYX53P3VT3TWMFO2ZDSPORZGMZNAITSLUXGSG4N4UYCYQIE"]
    }
}
collection = collection[providerEnv]
export default collection